<template>
    <div v-if="!wspLoader" class="workspace__body">
        <page-header title="Заказы" icon="user-secret" />
        <router-view></router-view>
        <table class="control-table">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Номер</th>
                    <th>От</th>
                    <th>Заказчик</th>
                    <th>Телефон</th>
                    <th>Оплата</th>
                    <th>Доставка</th>
                    <th>На дату</th>
                    <th>Сумма</th>
                    <th>Статус</th>
                </tr>
            </thead>
            <tbody> 
                <tr v-for="order of orders" :key="order.id" :class="{comlete: order.status !== 'Новый заказ'}">
                    <td>
                        <router-link :to="'/orders/' + order.id">
                            {{ order.id }}
                        </router-link>
                    </td>
                    <td>
                        <router-link :to="'/orders/' + order.id">
                            {{ order.number }}
                        </router-link>
                    </td>
                    <td>
                        <router-link :to="'/orders/' + order.id">
                            {{ order.number[4] + order.number[5] + '.' + order.number[2] + order.number[3] + '.20' + order.number[0] + order.number[1] }}
                        </router-link>
                    </td>
                    <td>
                        <router-link :to="'/orders/' + order.id">
                            {{ order.name.split('|')[0] }}
                        </router-link>
                    </td>
                    <td>
                        <router-link :to="'/orders/' + order.id">
                            {{ order.phone }}
                        </router-link>
                    </td>
                    <td>
                        <router-link :to="'/orders/' + order.id">
                            {{ order.payment }}
                        </router-link>
                    </td>
                    <td>
                        <router-link :to="'/orders/' + order.id">
                            {{ order.delivery }}
                        </router-link>
                    </td>
                    <td>
                        <router-link :to="'/orders/' + order.id">
                            {{ order.shippingDate }}
                        </router-link>
                    </td>
                    <td>
                        <router-link :to="'/orders/' + order.id">
                            {{ sum(order.order) }}
                        </router-link>
                    </td>
                    <td class="unlink">
                        <button v-if="order.status === 'Завершен'" class="btn-delete" @click="deleteItem(order.id)">
                            <font-awesome-icon icon="trash" /> Удалить <span>(Завершен)</span>
                        </button>
                        <span v-else>{{ order.status }}</span>
                    </td>
                </tr>               
            </tbody>
        </table>
        
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    name: 'Orders',
    methods: {
        ...mapActions({
            getOrders: 'getOrders',
            deleteOrder: 'deleteOrder',
        }),
        sum: order => {
            const podarki = order.podarki.reduce((r, item) => r + item.value * item.price, 0)
            const attach = order.attachments.reduce((r, item) => r + item.value * item.price, 0)
            return podarki + attach
        },
        deleteItem(id) {
            if(confirm("Заказ будет удален. Продолжить?")) {
                this.deleteOrder(id)
            }
        }
    },
    computed: {
        ...mapGetters({
            wspLoader: 'GetWspLoader',
            orders: 'GetOrders'
        }),
    },
    async created() {
        this.getOrders()
    }
}
</script>